import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import useAppContext from 'app/store/useAppContext'

export const useReactPixelMetric = () => {
  const location = useLocation()
  const searchParamsCurrent = new URLSearchParams(location.search)
  const metricValueCurrent = searchParamsCurrent.get('metric')

  const {
    store: {
      user: { searchParams },
    },
  } = useAppContext()

  useEffect(() => {
    const metric = searchParams?.metric || metricValueCurrent
    const ReactPixel = require('react-facebook-pixel')
    ReactPixel.default.init(metric?.toString())
    ReactPixel.default.track('Lead')
  }, [])
}
